import React from "react";
import styles from "./CoreValues.module.css";
import QuestionsMoon from "../questionsMoon/QuestionsMoon";

const data = [
  {
    number: "01",
    question: "Security & Transparency",
    answer:
      "MoonVault makes sure that every transaction and procedure is safe and verifiable on the blockchain by prioritizing user confidence through strong security standards and open operations.",
  },
  {
    number: "02",
    question: "Community-Driven Innovation",
    answer:
      "By fostering a collaborative ecosystem where community feedback and input drives ongoing platform development, MoonVault gives users the power to influence how decentralized finance is developed in the future.",
  },
  {
    number: "03",
    question: "Balanced Measures",
    answer:
      "MoonVault strives to separate it’s centralized supportive nature of $VAULT from the decentralized nature of the $VAULT token. The MoonVault project and the $VAULT token maintain a harmoniously balanced distinction.",
  },
];

const CoreValues = () => {
  return (
    <section id="CORE-VALUES" className="flex justify-center py-20 text-white">
      <div className="flex-col lg:flex lg:flex-row w-[95%] md:w-[85%]">
        <div className="lg:w-[25%] pb-5 lg:pb-0">
          <h4 className={styles.tagline}>/ CORE VALUES</h4>
        </div>
        <div className="lg:w-[75%] p-[0.25rem] lg:p-[1rem]">
          <QuestionsMoon data={data} />
        </div>
      </div>
    </section>
  );
};

export default CoreValues;
