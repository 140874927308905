import React from "react";
import styles from "./About.module.css";
const data = [
    {
        subHeading: "Replenish",
        heading: "Liquidity Infusions",
        content:
            " Initial liquidity for $VAULT is burned. The replenishment functions trigger decentralized liquidity infusions, enhancing market stability and reducing price impact for future trades. These infusions grow LP depth without causing sells on the chart, ensuring a stable and resilient liquidity pool.",
        video: "/assets/videos/lp.mp4",
    },
    {
        subHeading: "Redistribute",
        heading: "Dual Dividends, Auto Buybacks",
        content:
            " The redistribution functions rotate between automatic $ETH rewards as $WETH and auto-buyback-and-reflect as $VAULT. This mechanism promotes long-term engagement and value growth by distributing both $ETH and $VAULT to holders, while supporting $VAULT's market price through buybacks.",
        video: "/assets/videos/reflect.mp4",
    },
    {
        subHeading: "Rebalance",
        heading: "Auto Burn",
        content:
            " The rebalancing mechanism reduces the total supply of $VAULT tokens through randomized True burns, ensuring a stable and appreciating token value while balancing the growing reserves accumulated through LP Infusions.",
        video: "/assets/videos/burn.mp4",
    },
];

const AboutVideos = () => {
    return (
        // About Parent Class
        <section id="ABOUT" className="flex justify-center w-full py-10">
            {/* About Child Class Starts */}
            <div className="AboutChild w-[95%] sm:w-[85%] text-white">
                <div className="aboutCards flex flex-wrap justify-center xl:justify-between gap-[1rem] pt-5">
                    {data.map((items, index) => {
                        return (
                            <div key={index}
                                className={`w-[100%] sm:w-[67%] md:w-[55%] xl:w-[32%] flex justify-between flex-col h-auto ${styles.aboutBox}`}
                            >
                                <div className="aboutBoxContent">
                                    <h3 className={styles.Replenishment}>{items.subHeading}</h3>
                                    <h1 className={styles.aboutBoxHeading}>{items.heading}</h1>
                                    <p className={styles.aboutBoxContent}>{items.content}</p>
                                </div>

                                <div className="video">
                                    <div>
                                        <video playsInline={true}
                                            muted={true}
                                            autoPlay={true}
                                            loop={true}
                                            controls={false}
                                            preload="auto"
                                            width="100%"
                                            height="100%" className="object-cover w-full h-full overflow-hidden pointer-events-none select-none">
                                            <source src={items.video} type="video/mp4" />
                                        </video>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default AboutVideos;
