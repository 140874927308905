import React from "react";
import styles from "./Faq.module.css";
import QuestionsMoon from "../questionsMoon/QuestionsMoon";

const data = [
  {
    number: "01",
    question: "Do I have to wait for my dividends to be distributed?",
    answer:
      "$VAULT rewards are available to claim every 15 minutes (since last claim), and buying, selling, or transferring $VAULT will automatically claim rewards.MoonVault funds an iterative script which processes $VAULT holder rewards randomly free of charge.",
  },
  {
    number: "02",
    question: "Is there a fee on transfers?",
    answer:
      "Yes, transfers incur the same fee as buys and sells to ensure that rewards are accounted for accurately and securely. Adding LP through the Helper contract does not incur a Transfer fee, LP removal Transfer fees are unavoidable.",
  },
  {
    number: "03",
    question: "Do I have to claim LP rewards?",
    answer:
      "No, rewards for liquidity providers are automatic and decentralized through the Liquidity Infusion process. There is no need to manually claim them. A Helper contract is deployed alongside $VAULT to allow smooth addition and removal of liquidity via smart contract or dApp.",
  },
];

const Faq = () => {
  return (
    <section id="FAQs" className="flex justify-center py-20 text-white">
      <div className="flex-col lg:flex lg:flex-row w-[95%] md:w-[85%]">
        <div className="lg:w-[25%] pb-5 lg:pb-0">
          <h4 className={styles.tagline}>/ FAQ'S</h4>
        </div>
        <div className="lg:w-[75%] p-[0.25rem] lg:p-[1rem]">
          <QuestionsMoon data={data} />
        </div>
      </div>
    </section>
  );
};

export default Faq;
