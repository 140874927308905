import React, { useState } from "react";
import "./TextReveal.css";

const randomChars = "~!#$%^&*0123456789_+=-|?";

function RandomTextReveal({ textToReveal = "Text" }) {
  const [revealedText, setRevealedText] = useState(textToReveal);

  const onHover = () => {
    const shuffledText = shuffleText(textToReveal);
    setRevealedText(shuffledText);
    //
    let currentIndex = 0;
    let newText = shuffledText.split("");

    const interval = setInterval(() => {
      newText[currentIndex] = textToReveal[currentIndex];
      setRevealedText(newText.join(""));
      currentIndex++;

      if (currentIndex === textToReveal.length) {
        clearInterval(interval);
      }
    }, 50); // Adjust the interval duration as needed
  };

  // Function to shuffle the text
  function shuffleText(text) {
    const textArray = text.split("");
    for (let i = 0; i < textArray.length; i++) {
      const char = getRandomChar();
      textArray[i] = char;
    }
    return textArray.join("");
  }

  const getRandomChar = () => {
    const randIdx = Math.floor(Math.random() * randomChars.length);
    return randomChars[randIdx];
  };

  return (
    <div className="random-text-container">
      <p
        className="revealed-text text-navbar-color hover:text-[--green-color]"
        onMouseEnter={onHover}
        // onMouseLeave={onMouseOut}
      >
        {revealedText}
      </p>
    </div>
  );
}

export default RandomTextReveal;
