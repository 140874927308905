import React from 'react'
import styles from "./Overview.module.css"
import OverviewCard from '../overviewCard/OverviewCard'

const content = [
    {
        title: "Replenish",
        description: "Automated liquidity pool infusions help mitigate the price impact of future trades and stabilize the market, providing direct LP rewards while boosting pool depth, all without triggering chart sales."
    },
    {
        title: "Redistribute",
        description: "Auto dividends alternate between distributing $ETH rewards as $WETH and performing auto-buybacks, reflecting as $VAULT. This system drives long-term growth by rewarding holders with both $ETH and $VAULT tokens."
    },
    {
        title: "Rebalance",
        description: "An automatic rebalancing process that reduces the total supply of $VAULT tokens via automated burns, supporting long-term stability and value appreciation."
    }
]

const Overview = () => {

    const isMobile = window.innerWidth <= 1023;

    return (
        <section className='flex flex-col gap-20 justify-center items-center w-full lg:-mt-[10%] relative'>
            <div className='w-[95%] sm:w-[85%] flex lg:flex-row gap-20 lg:gap-0 flex-col-reverse h-full items-center justify-between py-[15%]' style={
                !isMobile
                    ? {
                        backgroundImage: "url('/assets/images/star-engine-bg.svg')",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "75%",
                        backgroundPosition: "left",
                    }
                    : {}
            }>
                <div id='STARR' className='relative w-full flex justify-center items-center lg:w-[50%]'>
                    <img src='/assets/images/earth.svg' className='w-[80%] lg:opacity-0' alt='bg-overview' />
                    <div className='absolute top-[30%] md:top-[40%] lg:top-[55%] xl:top-[60%] 2xl:top-[67.5%] left-[15.5%] lg:-left-[8.5%] 2xl:-left-[7.5%] w-[70%] lg:w-full flex flex-col items-center justify-center gap-1 lg:gap-3'>
                        <div className='w-[80%] lg:w-[30%]'>
                            <h2 className={`text-xl md:text-2xl text-center text-white`}>The S.T.A.R.R. Engine</h2>
                        </div>
                        <div className='w-[80%] lg:w-[40%] xl:w-[20%] text-center'>
                            <p className={styles.textOverview}>(Self-Triggered Automatic Regeneration and Redistribution)</p>
                        </div>
                    </div>
                    <img src='/assets/images/overview-three-planes-two.png' alt='star-engine' className='-top-[12.5%] lg:top-[18.5%] w-full left-0 lg:-left-[7.75%] absolute animate-animateSlowSpin' />
                </div>
                <div className='flex items-center justify-center lg:w-[50%] pt-[25%]'>
                    <div className='flex flex-col relative w-[95%] lg:w-[65%] gap-5 p-10 border border-white'>
                        <div className="absolute top-[10%] -left-[1.5%] h-[80%]">
                            <div className="bg-[#6142ff] w-[5px] h-full" />
                        </div>
                        <div>
                            <h2 className={`text-2xl text-white`}>Overview</h2>
                        </div>
                        <div>
                            <p className={styles.textOverview}>The $VAULT token features first-of-its-kind technology that implements automatic rotational tokenomic functionality featuring: Buyback-and-Reflect in $VAULT, $ETH rewards (as $WETH), Supply Reductions, and Decentralized Liquidity Infusions.
                            </p>
                        </div>
                        <div>
                            <p className={styles.textOverview}>This is all made possible, with minimal gas fees, by the S.T.A.R.R. Engine.</p>
                        </div>
                        <div className="absolute top-[10%] -right-[1.5%] h-[80%]">
                            <div className="bg-[#6142ff] w-[5px] h-full" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-[95%] sm:w-[85%] flex h-full items-center justify-between'>
                <div className='relative grid w-full grid-cols-1 gap-10 lg:grid-cols-3'>
                    {
                        content?.map((item, index) => (
                            <div className='col-span-1' key={index}>
                                <OverviewCard item={item} />
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default Overview
