import React, { useEffect, useRef } from "react";
import styles from "./Framework.module.css";
import Button from "../button/Button"

//GSAP
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const topdata = [
  {
    img: "/assets/images/logo.png",
    heading: "Function Alerts: Real-Time Updates",
    subContent:
      "Our Token BuyBot keeps you informed with instant notifications of S.T.A.R.R. Engine function changes.",
  },
  {
    img: "/assets/images/logo.png",
    heading: "Tokenomics Insights: Stay Informed",
    subContent:
      "The Statistics Bot provides easy access to the latest tokenomics data, allowing you to track $VAULT’s performance directly in the chat.",
  },
];

const bottomdata = [
  {
    img: "/assets/images/logo.png",
    heading: "Seamless Rewards: Automated & Gas-Free*",
    subContent:
      "The $VAULT Rewards Telegram Bot streamlines reward checking and claiming, alongside the dApp. Legacy Protocol, an automated claims processing system funded by the project, processes rewards iteratively through a script ensuring long-term holders benefit without additional costs.",
  },
  {
    img: "/assets/images/logo.png",
    heading: "Tech Innovations: Beyond the Bots",
    subContent:
      "MoonVault is committed to releasing new tools and features that will further enhance the user experience and ecosystem functionality.",
  },
];

const Framework = () => {
  const frameworkRef = useRef([]);

  useEffect(() => {
    frameworkRef.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        {
          autoAlpha: 0,
          x: "10%",
          // markers:true
        },
        {
          duration: 0.5,
          autoAlpha: 1,
          x: 0,
          ease: "power2.out",
          scrollTrigger: {
            trigger: el,
            start: "top bottom-=20%",
            toggleActions: "play none none reverse",
            // markers:true,
          },
        }
      );
    });
  }, []);

  const addToRefs = (el) => {
    if (el && !frameworkRef.current.includes(el)) {
      frameworkRef.current.push(el);
    }
  };
  return (
    // Framework Section Starts
    <section id="FRAMEWORK" className="flex justify-center w-full pt-5 pb-20 FrameworkParent">
      {/*Framework Child Section Starts */}
      <div className="FrameworkChild  w-[95%] sm:w-[85%]  text-white">
        <div className="py-20 context">
          {/* TagLine */}
          <h4 className={styles.tagline}>/ FRAMEWORK</h4>
          {/* Heading */}
          <h3 className={`w-full lg:w-[59%] ${styles.heading}`}>
            Unveiling the MoonVault Ecosystem
          </h3>
          {/* Sub-Content */}
          <p className={styles.subcontent}>
            Discover the power of MoonVault’s advanced Telegram Bots and automated systems, designed to enhance your experience:
          </p>
          <div className="flex pl-2 py-7">
            <div>
              <Button label={"MEDIUM"} link={"https://medium.com/@moonvaulteth"} />
            </div>
          </div>
        </div>

        <div className="flex flex-wrap gap-9">
          <div className="flex flex-wrap gap-4 topclass">
            {topdata.map((items, index) => {
              return (
                <div ref={addToRefs}
                  className={`flex ${styles.card} items-stretch w-full flex-col gap-10 h-auto sm:h-[300px] ${index % 2 === 0 ? "lg:w-[50%]" : "lg:w-[45%]"
                    }`}
                  key={index}
                >
                  <div className="flex justify-between">
                    <h2 className={styles.tagline}>{items.heading}</h2>
                    <div className="image w-[30%] sm:w-[11%] xl:w-[15%] flex justify-end">
                      <img src={items.img} className="max-w-[45px] lg:max-w-[55px]" alt="globe" />
                    </div>
                  </div>

                  <p className={styles.subcontent}>{items.subContent}</p>
                </div>
              );
            })}
          </div>
          <div className="flex flex-wrap justify-end gap-4 bottomclass">
            {bottomdata.map((items, index) => {
              return (
                <div ref={addToRefs}
                  className={`flex ${styles.card} w-full h-auto flex-col gap-10 sm:h-[300px] ${index % 2 === 0 ? "lg:w-[50%]" : "lg:w-[45%]"
                    }`}
                  key={index}
                >
                  <div className="flex justify-between">
                    <h2 className={styles.tagline}>{items.heading}</h2>
                    <div className="image w-[30%] sm:w-[11%] xl:w-[15%] flex justify-end">
                      <img src={items.img} className="max-w-[45px] lg:max-w-[55px]" alt="globe" />
                    </div>
                  </div>

                  <p className={styles.subcontent}>{items.subContent}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/*Framework Child Section Ends */}
    </section>
    // Framework Section Ends
  );
};

export default Framework;
