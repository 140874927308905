import React from "react";
import styles from "./Blueprint.module.css";
import QuestionsMoon from "../questionsMoon/QuestionsMoon";

const data = [
  {
    number: "01",
    question: "Innovative Technology",
    answer:
      "MoonVault leverages the latest in blockchain and financial tools to create a powerful and intuitive platform, ensuring users benefit from cutting-edge advancements across sectors.",
  },
  {
    number: "02",
    question: "Integrated Experience",
    answer:
      "By supporting the $VAULT token, MoonVault combines decentralized rewards with advanced financial solutions, providing a seamless and enhanced on-chain experience.",
  },
  {
    number: "03",
    question: "Sustainable Growth",
    answer:
      "MoonVault’s commitment to long-term value is reflected in its support of $VAULT's decentralized tokenomics, designed to foster enduring utility and growth within the ecosystem.",
  },
];

const Blueprint = () => {
  return (
    <section id="BLUEPRINT" className="flex justify-center py-20 text-white">
      <div className="flex-col lg:flex lg:flex-row w-[95%] md:w-[85%]">
        <div className="lg:w-[25%] pb-5 lg:pb-0">
          <h4 className={styles.tagline}>/ BLUEPRINT</h4>
        </div>
        <div className="lg:w-[75%] p-[0.25rem] lg:p-[1rem]">
          <QuestionsMoon data={data} />
        </div>
      </div>
    </section>
  );
};

export default Blueprint;
