import React from "react";
import styles from "./SocialCommunity.module.css";
import Button from "../button/Button";

const SocialCommunity = () => {
  return (
    <section className="flex justify-center py-4">
      <div className="w-[95%] md:w-[85%] p-[1rem] border border-[#6142ff] text-white">
        <div className="flex flex-col py-2 lg:flex-row">
          <div className="W-[100%] lg:w-[50%] p-[0.25rem] lg:pb-20 lg:p-[1rem] gap-1 flex flex-col">
            <div className="lg:w-[70%]">
              <h4 className={`${styles.socialCommunityContentText}`}>You’re Invited</h4>
            </div>

            <div className="flex items-center">
              <h3 className={`${styles.socialCommunityHeadingName} `}>
                COMMUNITY
              </h3>
            </div>

            <div className="lg:w-[100%] pb-4">
              <p
                className={`text-dull-color ${styles.socialCommunityContentSubText}`}
              >
                The $VAULT token is built to drive decentralized engagement across the globe. MoonVault acts as a hub where users can connect, collaborate, and navigate their journey in the crypto space securely.
              </p>
            </div>
            <div className="flex gap-6 pb-10 pl-2 lg:gap-10 lg:pb-0">
              <div>
                <Button label={"TELEGRAM"} link={"https://t.me/MoonVaultETH"} />
              </div>
              <div>
                <Button label={"X/TWITTER"} link={"https://x.com/MoonVaultETH"} />
              </div>
              <div>
                <Button label={"DISCORD"} />
              </div>
            </div>
          </div>

          <div className="w-[100%] lg:w-[50%] lg:p-[0.25rem] lg:px-[1rem]">
            <img
              src="/assets/images/socialSectionEarth.jpg"
              alt="Earth"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SocialCommunity;
