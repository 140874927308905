import { Toaster } from "sonner";
import "./App.css";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import MarqueeComponent from "./components/marqueeComponent/marqueeComponent";
import Home from "./pages/Home";

function App() {
  return (
    <div className="overflow-x-hidden w-[100%] max-w-[1600px] mx-auto">
      <MarqueeComponent />
      <Header />
      <style jsx>{`
        ::-webkit-scrollbar {
          width: 4px !important;
          background-color: transparent;
        }

        ::-webkit-scrollbar-thumb {
          background-color: #6142ff;
        }
      `}</style>
      <Home />
      <Footer />
      <Toaster position="top-right" richColors closeButton />
    </div>
  );
}

export default App;
