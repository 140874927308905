import React, { useEffect, useRef, useState } from "react";
import styles from "./QuestionsMoon.module.css";

//GSAP
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const QuestionsMoon = ({ data }) => {
  const questionsRef = useRef([]);

  const [showAnswers, setShowAnswers] = useState(Array(data.length).fill(false)); // Array of state values

  const toggleAnswer = (index) => {
    const updatedAnswers = [...showAnswers];
    updatedAnswers[index] = !updatedAnswers[index];
    setShowAnswers(updatedAnswers); // Toggle the state for the specific question
  };

  useEffect(() => {
    questionsRef.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        {
          autoAlpha: 0,
          x: "10%",
          // markers:true
        },
        {
          duration: 0.5,
          x: 0,
          autoAlpha: 1,
          ease: "power2.out",
          scrollTrigger: {
            trigger: el,
            start: "top bottom-=20%",
            toggleActions: "play none none reverse",
            // markers:true,
          },
        }
      );
    });
  }, []);

  const addToRefs = (el) => {
    if (el && !questionsRef.current.includes(el)) {
      questionsRef.current.push(el);
    }
  };

  return (
    <div>
      {data.map((items, index) => (
        <div ref={addToRefs}
          key={index}
          onClick={() => toggleAnswer(index)}
          className={`py-8 ${index === data.length - 1
              ? "border border-b-[#6142ff] border-l-0 border-r-0 border-t-[#6142ff]"
              : "border border-b-0 border-l-0 border-r-0 border-t-[#6142ff]"
            }`}
        >
          <div className="flex justify-between cursor-pointer">
            <div className="flex gap-5 lg:gap-16">
              <div>
                <h3 className={`${styles.tagline} font-bold`}>
                  {items.number}
                </h3>
              </div>
              <div className="flex flex-col">
                <div>
                  <h3 className={`${styles.tagline} font-bold`}>
                    {items.question}
                  </h3>
                </div>
                <div className="pl-1 w-[95%]">
                  {showAnswers[index] && (
                    <p
                      className={`text-dull-color mt-4  ${styles.footerContentSubText}`}
                    >
                      {items.answer}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="mr-1 lg:mr-0 lg:pr-10">
              <div
                className={`${showAnswers[index]
                    ? "-rotate-90 transform transition-transform duration-500"
                    : "transform transition-transform duration-500"
                  }`}
              >
                <svg
                  className="rotate-45"
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 33 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M32.2305 14.6182L15.458 27.1426V23.1143L26.0537 15.8389L25.8096 15.1797C23.9541 15.4971 21.6104 15.6924 19.6572 15.6924H0.785156V12.1279H19.6572C21.5859 12.1279 23.9297 12.3232 25.8096 12.6162L26.0537 12.0059L15.458 4.73047V0.65332L32.2305 13.2021V14.6182Z"
                    fill="#6142ff"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default QuestionsMoon;
