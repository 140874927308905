import React from "react";
import styles from "./About.module.css";

const About = () => {
  return (
    // About Parent Class
    <section id="ABOUT" className="flex justify-center w-full py-10 AboutParent">
      {/* About Child Class Starts */}
      <div className="AboutChild w-[95%] sm:w-[85%] text-white">
        {/* Content Class */}
        <div className="context mb-[5rem]">
          {/* TagLine */}
          <h4 className={styles.tagline}>/ ABOUT MOONVAULT</h4>
          {/* Heading */}
          <h3 className={`w-full lg:w-[55%] ${styles.heading}`}>
            POWERED BY S.T.A.R.R.
          </h3>
          {/* Sub-Content */}
          <p className={`w-full lg:w-[45%] ${styles.subcontent}`}>
            MoonVault is a user-centric blockchain platform that seamlessly integrates advanced on-chain solutions with innovative financial tools, alongside a robust decentralized rewards system via $VAULT, to deliver a seamless user experience.
          </p>
          <p className={`w-full lg:w-[45%] ${styles.subcontent}`}>
            Built on the Ethereum network, MoonVault supports the $VAULT token. The S.T.A.R.R. Engine rotates through multiple functions, including liquidity infusions, token burns, token buybacks, and a decentralized rewards system which offers substantial potential earnings in both Ether ($ETH) and $VAULT tokens.
          </p>
          <p className={`w-full lg:w-[45%] ${styles.subcontent}`}>
            By balancing cutting-edge technology with decentralized tokenomics, MoonVault provides users with both real-world utility and a superior financial experience, all within a secure and efficient ecosystem.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
