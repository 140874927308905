import React from "react";
import About from "./../components/about/About";
import Blueprint from "./../components/blueprint/Blueprint";
import Faq from "./../components/faq/Faq";
import Hero from "./../components/hero/Hero";
import SocialCommunity from "./../components/socialCommunity/SocialCommunity";
import Tokenomics from "./../components/tokenomics/Tokenomics";
import Framework from "./../components/framework/Framework";
import AboutVideos from "../components/about-videos/AboutVideos";
import MarqueeLogos from "../components/logo-marquee/LogoMarquee";
import CoreValues from "../components/core-values/CoreValues";
import Overview from "../components/overview/Overview";

const Home = () => {
  return (
    <div className="w-[100%] max-w-[1600px] mx-auto">
      <Hero />
      <About /> 
      <MarqueeLogos />
      <Blueprint />
      <Tokenomics />
      <Overview />
      <Framework />
      <AboutVideos />
      <CoreValues />
      <Faq />
      <SocialCommunity />
    </div>
  );
};

export default Home;
