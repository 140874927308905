import React from "react";
import styles from "./Footer.module.css";
import RandomTextReveal from "../TextReveal/TextReveal";

const Footer = () => {
  const coreConnectLinks = [
    { linkName: "Home", linkUrl: "#HOME" },
    { linkName: "About", linkUrl: "#ABOUT" },
    { linkName: "Blueprint", linkUrl: "#BLUEPRINT" },
    { linkName: "Tokenomics", linkUrl: "#TOKENOMICS" },
    { linkName: "STARR", linkUrl: "#STARR" },
    { linkName: "Framework", linkUrl: "#FRAMEWORK" },
    { linkName: "Core Values", linkUrl: "#CORE-VALUES" },
    { linkName: "FAQs", linkUrl: "#FAQs" },
  ];

  const coreTokenLinks = [
    { linkName: "DEX Tools", linkUrl: "https://www.dextools.io/app/en/ether/pair-explorer/0xb057a78c16915172090dbcd71390195aa973d65b?t=1728946885614", isExternal: true },
    { linkName: "DEX Screener", linkUrl: "https://dexscreener.com/ethereum/0x220125af9da649e368813733ec8702a4145f996b", isExternal: true },
    { linkName: "Gecko Terminal", },
    { linkName: "Etherscan", linkUrl: "https://etherscan.io/address/0x220125af9da649e368813733ec8702a4145f996b", isExternal: true },
    { linkName: "CoinMarketCap (soon)", },
    { linkName: "Coin Gecko (soon)", },
  ];

  const moonBotLinks = [
    { linkName: "$VAULT RewardsBot", linkUrl: "https://t.me/MoonVault_Rewards_Bot", },
    { linkName: "$VAULT StatsBot", linkUrl: "https://t.me/MoonVault_Stats_Bot" },
    { linkName: "$VAULT BuyBot", linkUrl: "https://t.me/MoonVault_Buy_Bot" },
  ];

  const usefulLinks = [
    {
      linkName: "Launch DAPP",
      linkUrl: "https://app.moonvault.io",
      isExternal: true
    },
    { linkName: "BUY $VAULT", linkUrl: "https://app.uniswap.org/explore/tokens/ethereum/0x220125af9da649e368813733ec8702a4145f996b", isExternal: true },
    { linkName: "WhitePaper", linkUrl: "https://moonvault.io/moon-vault_wp.pdf", isExternal: true },
    { linkName: "GitBook (soon)", },
    { linkName: "GitHub", linkUrl: "https://github.com/MoonVaultETH", isExternal: true },
    { linkName: "Email", linkUrl: "mailto:admin@moonvault.io", isExternal: true },
  ];

  const scrollToSection = (event, sectionId) => {
    event.preventDefault();
    const section = document.querySelector(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const renderLinks = (links, isCoreConnect = false) =>
    links.map((items, index) => (
      <li key={index} className={`${styles.footerLinkItem} w-max`}>
        <a
          href={items.linkUrl}
          className={`${styles.footerLinks} font-medium cursor-pointer`}
          target={items.isDownload ? "_self" : "_blank"}
          rel="noopener noreferrer"
          onClick={
            isCoreConnect && !items.isDownload
              ? (e) => scrollToSection(e, items.linkUrl)
              : undefined
          }
        >
          <RandomTextReveal textToReveal={items.linkName} />
        </a>
      </li>
    ));



  return (
    <section className={`bg-black text-white py-8 ${styles.footer}`}>
      <div className={`w-[95%] md:w-[85%] mx-auto border border-[#6142ff] px-5 lg:px-12 pt-8 ${styles.footerBackground}`}>
        <div className="flex flex-col gap-8 lg:flex-row lg:gap-32">
          <div className="w-full lg:w-[35%]">
            <div className="flex items-center gap-4 mb-6">
              <img
                src="/assets/images/logo.png"
                alt="Logo"
                width="38px"
                height="38px"
              />
              <h2 className={`${styles.footerHeadingName} text-[#e4e4e4]`}>
                MOONVAULT
              </h2>
            </div>
            <h4 className={`${styles.footerContentText} mb-4`}>
              Transform Your Crypto Experience
            </h4>
            <p className={`text-dull-color ${styles.footerContentSubText}`}>
              Our core values reflect our mission to build a blockchain platform that merges cutting-edge technology with innovative solutions.<br /><br />
              The unique framework, enhanced by dynamic scenarios and a suite of custom Telegram bots, unveils groundbreaking decentralized tokenomics designed for lasting impact.
            </p>
          </div>
          <div className="w-full lg:w-[65%] gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-4 lg:mt-[90px]">
            <div className="col-span-1">
              <h5 className={`${styles.footerSectionHeading}`}>MoonVault</h5>
              <ul>{renderLinks(coreConnectLinks, true)}</ul>

            </div>
            <div className="col-span-1">
              <h5 className={`${styles.footerSectionHeading}`}>$VAULT</h5>
              <ul>{renderLinks(coreTokenLinks)}</ul>
            </div>
            <div className="col-span-1">
              <h5 className={`${styles.footerSectionHeading}`}>TELEGRAM BOTS</h5>
              <ul>{renderLinks(moonBotLinks)}</ul>
            </div>
            <div className="col-span-1">
              <h5 className={`${styles.footerSectionHeading}`}>HELPFUL LINKS</h5>
              <ul>{renderLinks(usefulLinks)}</ul>
            </div>
          </div>
        </div>
        <div className="flex md:justify-center items-center lg:text-center gap-6 border-t border-[#6142ff] mt-8 pt-4 text-sm">
          <h4 className={`${styles.footerContentText} mt-2`}>
            Social Links:
          </h4>
          <div className="flex items-center space-x-6 md:justify-center">
            <a href="https://t.me/MoonVaultETH" target="__blank" rel="noopener noreferrer">
              <img src={'assets/icons/telegram.svg'} alt="Facebook" className="w-10 h-10 md:w-5 md:h-5 socialIcon" />
            </a>
            <a href="#" target="__blank" rel="noopener noreferrer">
              <img src={'assets/icons/discord.svg'} alt="Instagram" className="w-10 h-10 md:w-5 md:h-5 socialIcon" />
            </a>
            <a href="https://x.com/MoonVaultETH" target="__blank" rel="noopener noreferrer">
              <img src={'assets/icons/twitter.svg'} alt="Twitter" className="w-10 h-10 md:w-5 md:h-5 socialIcon" />
            </a>
            <a href="https://medium.com/@moonvaulteth" target="__blank" rel="noopener noreferrer">
              <img src={'assets/icons/medium.svg'} alt="Youtube" className="w-10 h-10 md:w-5 md:h-5 socialIcon" />
            </a>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-4 md:gap-0 justify-between items-start w-full border-t border-[#6142ff] mt-4 py-4 text-xs">
          <div className="flex-col items-center justify-start">
            <p className={`text-dull-color ${styles.footerCopyrightText}`}>
              &copy; All Rights Reserved
            </p>
            <p className={`text-dull-color ${styles.footerCopyrightText}`}>
              Disclaimer: MoonVault is an experimental DeFi project and not
              intended as financial advice, an investment, or any form of
              security. MoonVault and $VAULT have no intrinsic value. Always
              conduct your own research before participating.
            </p>
            <p className={`text-dull-color ${styles.footerCopyrightText}`}>
              * - claiming rewards via dApp or Telegram incurs nominal
              gas fees, iterative processing does not ensure selection.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
