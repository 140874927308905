import React from "react";
import Marquee from "react-fast-marquee";

const MarqueeLogos = () => {
    const logosTop = [
        { name: "CoinBase", logo: '/assets/images/marquee-logos/gecko-terminal.svg' },
        { name: "Uniswap", logo: '/assets/images/marquee-logos/dextools.svg' },
        { name: "Taiko", logo: '/assets/images/marquee-logos/uniswap.svg' },
        { name: "Bittensor", logo: '/assets/images/marquee-logos/dexCleaner.svg' },
        { name: "CoinMarketCap", logo: '/assets/images/marquee-logos/coinGecko.svg' },
    ];

    const logosBottom = [
        { name: "STARKNET", logo: '/assets/images/marquee-logos/hostinger.svg' },
        { name: "LINEA", logo: '/assets/images/marquee-logos/metaMask.svg' },
        { name: "BASE", logo: '/assets/images/marquee-logos/infura.svg' },
        { name: "ZORA", logo: '/assets/images/marquee-logos/etherScan.svg' },
        { name: "BASE", logo: '/assets/images/marquee-logos/CoinMarketCap.svg' },
    ];

    return (
        <div className="flex flex-col space-y-8 overflow-hidden">
            {/* Upper marquee moving to the right */}
            <Marquee className="marquee" duration={20000}>
                {logosTop.concat(logosTop).map((logo, index) => (
                    <div key={index} className="flex-shrink-0 px-8">
                        <img
                            src={logo.logo}
                            alt={logo.name}
                            className="h-16 w-[250px] rounded-xl border border-[#6142FF] px-6 py-3"
                        />
                    </div>
                ))}
            </Marquee>

            {/* Lower marquee moving to the left */}
            <Marquee className="marquee" duration={20000} direction="right">
                {logosBottom.concat(logosBottom).map((logo, index) => (
                    <div key={index} className="flex-shrink-0 px-8">
                        <img
                            src={logo.logo}
                            alt={logo.name}
                            className="h-16 w-[250px] rounded-xl border border-[#6142FF] px-6 py-3"
                        />
                    </div>
                ))}
            </Marquee>
        </div>
    );
};

export default MarqueeLogos;
