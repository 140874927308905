import React from "react";
import styles from "./Button.module.css";

const Button = ({ label, link, onClick }) => {
  // Check if the link starts with 'http://' or 'https://'
  const isExternalLink = link?.startsWith("http://") || link?.startsWith("https://");

  return (
    <>
      {isExternalLink ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <button className={`${styles.button}`}>{label}</button>
        </a>
      ) : (
        <button onClick={onClick} className={`${styles.button} cursor-pointer`}>
          {label}
        </button>
      )}
    </>
  );
};

export default Button;
