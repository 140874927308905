import React from 'react'
import styles from "../overview/Overview.module.css"

const OverviewCard = ({ item }) => {
    return (
        <div className='relative flex items-center justify-center w-full'>
            <img src='/assets/images/overiew-card.svg' alt='Overview card' />
            <div className='absolute flex flex-col items-center text-center w-full gap-1 lg:gap-2.5 lg:px-10'>
                <div>
                    <h2 className={`text-2xl text-white`}>{item?.title}</h2>
                </div>
                <div className='w-[70%] sm:w-[50%] md:w-[40%] lg:w-[95%] xl:w-[80%]'>
                    <p className={styles.textOverview}>{item?.description}</p>
                </div>
            </div>
        </div>
    )
}

export default OverviewCard
