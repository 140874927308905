import React, { useEffect, useRef } from "react";
import styles from "./Tokenomics.module.css";
import Button from "../button/Button";

//GSAP
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { toast } from "sonner";

gsap.registerPlugin(ScrollTrigger);

const leftdata = [
  {
    btnText: "5% BUY TAX",
  },
  {
    btnText: "5% SELL TAX",
  },
  {
    btnText: "LP BURNED",
  },
];
const rightdata = [
  {
    btnText: "3% S.T.A.R.R",
  },
  {
    btnText: "1% DEVELOPMENT",
  },
  {
    btnText: "1% MARKETING",
  },
];

const Tokenomics = () => {
  const tokenomicsRef = useRef([]);

  useEffect(() => {
    tokenomicsRef.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        {
          autoAlpha: 0,
          // markers:true
        },
        {
          duration: 2,
          autoAlpha: 1,
          ease: "power2.out",
          scrollTrigger: {
            trigger: el,
            start: "top bottom-=20%",
            toggleActions: "play none none reverse",
            // markers:true,
          },
        }
      );
    });
  }, []);

  const addToRefs = (el) => {
    if (el && !tokenomicsRef.current.includes(el)) {
      tokenomicsRef.current.push(el);
    }
  };

  const copyToClipboard = () => {
    const address = '0x220125AF9DA649e368813733eC8702A4145F996b';
    navigator.clipboard.writeText(address)
      .then(() => {
        toast.success('Address copied to clipboard!');
      })
      .catch((err) => {
        toast.error('Failed to copy: ', err);
      });
  };

  return (
    // Tokenomics Parent Class
    <section
      id="TOKENOMICS"
      className="flex justify-center w-full pt-20 TokenomicsParent"
    >
      {/* Tokenomics Child Class Starts */}
      <div className="TokenomicsChild w-[95%] sm:w-[85%]  text-white">
        {/* Content Class */}
        <div className="mb-10 context">
          {/* TagLine */}
          <h4 className={styles.tagline}>/ TOKENOMICS</h4>
          {/* Heading */}
          <h3 className={`w-full lg:w-[50%] ${styles.heading}`}>
            Optimized Tokenomics for Sustainability and Growth
          </h3>
          {/* Sub-Content */}
          <p className={`${styles.subcontent} lg:w-[45%]`}>
            The decentralized tokenomics of $VAULT, supported by MoonVault’s technological advancements, are designed to ensure long-term value and utility.
          </p>
        </div>
        <div className="flex gap-6 pb-10 pl-2 lg:gap-10 lg:pb-0">
          <div>
            <Button label={"COPY ADDRESS"} onClick={copyToClipboard} />
          </div>
          <div>
            <Button label={"UNISWAP"} link={"https://app.uniswap.org/explore/tokens/ethereum/0x220125af9da649e368813733ec8702a4145f996b"} />
          </div>
          <div>
            <Button label={"DEXTOOLS"} link={"https://www.dextools.io/app/en/ether/pair-explorer/0xb057a78c16915172090dbcd71390195aa973d65b?t=1728946885614"} />
          </div>
        </div>
        {/* Moon Base Total Supply Class */}
        <div ref={addToRefs} className="totalSupplyContainer flex flex-col md:flex-row items-center justify-between gap-[5rem] md:gap-[0rem]">
          <div className="leftSideContainer space-y-8 w-[100%] md:w-[22%] cursor-pointer">
            {leftdata.map((items, index) => {
              return (
                <div key={index}>
                  <h4 className={styles.tokonomicsBox}>{items.btnText}</h4>
                </div>
              );
            })}
          </div>
          <div className="centerMediaHighlight space-x-5 flex justify-center items-center py-[13rem] cursor-pointer ">
            <div className="animationOvals absolute w-[70%] md:w-[35%] lg:w-[30%]">
              <div>
                <img
                  src="/assets/images/ovaal1.png"
                  className={`absolute ${styles.animationCircle}`}
                  alt="oval1"
                />
              </div>
              <div>
                <img
                  src="/assets/images/ovaal2.png"
                  className={`relative ${styles.animationCircle}`}
                  alt="oval2"
                />
              </div>
            </div>
            <div className="absolute flex flex-col space-x-0 centerContent sm:flex-row sm:space-x-5">
              <h1 className="text-[1.8rem] lg:text-[2.13rem]">MOONVAULT</h1>
              <div>
                <p>TOTAL SUPPLY</p>
                <p className={styles.digits}>1,000,000,000</p>
              </div>
            </div>
          </div>
          <div className="rightSideContainer space-y-8 w-[100%] md:w-[22%]">
            {rightdata.map((items, index) => {
              return (
                <div key={index}>
                  <h4 className={styles.tokonomicsBox}>{items.btnText}</h4>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* Tokenomics Child Class Ends */}
    </section>
  );
};

export default Tokenomics;
