import { useState } from "react";
import { HeaderLinks } from "./Header";
import styles from "./Header.module.css";
import RandomTextReveal from "../TextReveal/TextReveal";

const MobileHeader = () => {
  const [navShow, setNavShow] = useState(false);

  const onToggleNav = () => {
    setNavShow((status) => {
      if (status) {
        document.body.style.overflow = "auto";
      } else {
        // Prevent scrolling
        document.body.style.overflow = "hidden";
      }
      return !status;
    });
  };

  return (
    <div className="lg:hidden">
      <div className="flex">
        <button
          type="button"
          className="w-8 h-8 py-2 ml-1 mr-1 rounded"
          aria-label="Toggle Menu"
          onClick={onToggleNav}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="text-white"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div
        className={`fixed top-0 left-0 z-100 h-[100%] w-[100%] transform bg-black ${navShow
          ? "-translate-x-[0px] -translate-y-[0px]"
          : "translate-x-full -translate-y-[0px]"
          }`}
        style={{ transition: "all 0.5s cubic-bezier(.68,-0.55,.27,1.55)" }}
      >
        <div className="flex justify-end">
          <button
            type="button"
            className="w-8 h-8 mt-3 mr-3 rounded"
            aria-label="Toggle Menu"
            onClick={onToggleNav}
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="15" cy="15" r="15" fill="#D1D1D1" />
              <path
                d="M21.6372 19.9289C22.1209 20.4131 22.1209 21.1662 21.6372 21.6503C21.3954 21.8924 21.0998 22 20.7774 22C20.4549 22 20.1593 21.8924 19.9175 21.6503L15 16.7281L10.0825 21.6503C9.84069 21.8924 9.54511 22 9.22265 22C8.90019 22 8.60461 21.8924 8.36276 21.6503C7.87908 21.1662 7.87908 20.4131 8.36276 19.9289L13.2802 15.0067L8.36276 10.0845C7.87908 9.60039 7.87908 8.84726 8.36276 8.36311C8.84645 7.87896 9.59885 7.87896 10.0825 8.36311L15 13.2853L19.9175 8.36311C20.4012 7.87896 21.1536 7.87896 21.6372 8.36311C22.1209 8.84726 22.1209 9.60039 21.6372 10.0845L16.7198 15.0067L21.6372 19.9289Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
        <nav
          className={`${navShow ? styles.mobile_nav_items : ""
            } fixed mt-14 h-full flex w-full flex-col items-center`}
        >
          {HeaderLinks.map((items, index) => (
            <div
              key={index}
              className={`py-[4%] w-[90%] flex justify-center ${index === HeaderLinks.length - 1
                ? "border border-b-[#6142ff] border-l-0 border-r-0 border-t-[#6142ff]"
                : "border border-b-0 border-l-0 border-r-0 border-t-[#6142ff]"
                }`}
            >
              <a href="/"
                className={`${styles.mobileHeaderLinks} font-medium cursor-pointer`}
              >
                <RandomTextReveal textToReveal={items.linkName} />
              </a>
            </div>
          ))}
          <div className="px-4 mt-10 py-[2.5%] w-[90%] border border-[#6142ff] cursor-pointer">
            <div>
              <a
                href="https://app.uniswap.org/explore/tokens/ethereum/0x220125af9da649e368813733ec8702a4145f996b"
                target="__blank" 
                className={`flex justify-center ${styles.mobileHeaderLinks}`}>
                <RandomTextReveal textToReveal="BUY $VAULT" />
              </a>
            </div>
          </div>
          <div className="px-4 mt-2 py-[2.5%] w-[90%] bg-[#6142ff] hover:bg-transparent hover:border hover:border-[#6142ff] cursor-pointer">
            <div>
              <a
                href="https://app.moonvault.io"
                target="__blank"
                className={`flex justify-center ${styles.mobileHeaderLinks}`}>
                <RandomTextReveal textToReveal="LAUNCH dAPP" />
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default MobileHeader;
