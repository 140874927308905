import React, { useState, useEffect } from "react";
import styles from "./Header.module.css";
import RandomTextReveal from "../TextReveal/TextReveal";
import MobileHeader from "./mobileHeader";

export const HeaderLinks = [
  { linkName: "ABOUT", linkUrl: "#ABOUT" },
  { linkName: "BLUEPRINT", linkUrl: "#BLUEPRINT" },
  { linkName: "TOKENOMICS", linkUrl: "#TOKENOMICS" },
  { linkName: "STARR", linkUrl: "#STARR" },
  { linkName: "FRAMEWORK", linkUrl: "#FRAMEWORK" },
  { linkName: "CORE VALUES", linkUrl: "#CORE-VALUES" },
  { linkName: "FAQ's", linkUrl: "#FAQs" },
];

const Header = () => {
  const [scrollY, setScrollY] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (event, sectionId) => {
    event.preventDefault();
    const section = document.querySelector(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navStyle = {
    position: "fixed",
    top: scrollY === 0 ? 45 : `${-scrollY * 0.00000000001}px`,
    left: 0,
    right: 0,
    zIndex: 10,
    transition: "top 0.25s ease-in-out",
  };
  return (
    <section
      style={navStyle}
      className={`${styles.parentNavbar} h-[70px] fixed border z-100 border-[#6142ff] bg-black text-white w-[100%] justify-center flex items-center max-w-[1600px] mx-auto`}
    >
      <div className="flex w-[98%] justify-between">
        <a href="/" className="flex gap-2">
          <div className="pl-4">
            <img
              src="/assets/images/logo.png"
              alt="Logo"
              width="30px"
              height="30px"
            />
          </div>
          <div className="flex items-center">
            <h2
              className={`${styles.navbarHeadingName} tracking-widest font-bold text-[#e4e4e4]`}
            >
              MOONVAULT
            </h2>
          </div>
        </a>
        <div className="items-center hidden gap-[2rem] lg:flex">
          {HeaderLinks.map((items, index) => (
            <div key={index}>
              <ul>
                <li className={`${styles.headerLinks}`}>
                  <a
                    // href={items.linkUrl}
                    className="flex justify-center cursor-pointer"
                    onClick={(e) => scrollToSection(e, items.linkUrl)}
                  >
                    <RandomTextReveal textToReveal={items.linkName} />
                  </a>
                </li>
              </ul>
            </div>
          ))}
          <div className="flex gap-4">
            <a target="__blank" href="https://app.uniswap.org/explore/tokens/ethereum/0x220125af9da649e368813733ec8702a4145f996b" className="px-4 py-2.5 border hover:bg-[#6142ff] border-[#6142ff] cursor-pointer">
              <div className="w-[70px]">
                <div className="flex justify-center">
                  <RandomTextReveal textToReveal="BUY $VAULT" />
                </div>
              </div>
            </a>
            <a
               target="__blank" href="https://app.moonvault.io" 
              className="px-4 py-2.5 bg-[#6142ff] border border-[#6142ff] hover:bg-transparent hover:border hover:border-[#6142ff] cursor-pointer">
              <div className="w-[80px]">
                <div className="flex justify-center">
                  <RandomTextReveal textToReveal="LAUNCH dAPP" />
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="lg:hidden">
          <MobileHeader />
        </div>
      </div>
    </section>
  );
};
export default Header;
